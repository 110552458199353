*{  
  letter-spacing: 0.05em;
    --lightText: #DDDDE8;
    --darkText: #333;
    --darkBg: #0A0942;
    --darkBgO: #0a0942ce;
    --lightBg: #7C8495;
    --extraBg: #7776A6;
    --lightBgO: whitesmoke;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  /* body{
    font-family: 'Baloo Tammudu 2', cursive !important; 

  } */
  
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--darkBg);
    border-radius: 0;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px var(--darkText);
    border-radius: 0;
  }
  
  ::selection {
    background-color: var(--darkBg);
    color: var(--lightText);
  }

.lit-topbar{
    position: fixed;
    z-index: 9999;
    background-color: var(--lightText);
    /* border: 4px solid var(--darkBg); */
    text-align: center;
    font-weight: 500;
    bottom: 10px;
    left: 10px;
    font-size: 22px;
    padding: 5px;
    width: 40px;
    border-radius: 20px;
  }

.lit-presentation-title{
  font-weight: 700;
  margin: 0;
  color: #FFFFFF;
  background: linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lit-wwdItem{
  transition: 0.5s;
  padding: 20px 0px;
}

.lit-container p, .lit-container h5{
  font-weight: 400 !important;
}

.lit-wwdItem:hover{
  box-shadow: 0px 0px 10px #3333;
  border-radius: 5px;
}

.lit-wwdItem > img{
  width: 100px;
}

.lit-container  h5{
  padding-top: 10px;
}

.lit-container{
  margin-top: 40px;
}

.lit-containerNoMargin{
  margin-top: 0px !important;
}

.lit-container h4{
  color: var(--darkBg);
  text-transform: uppercase;
  padding-bottom: 30px;
  font-weight: 600;
}

.lit-bg-ws{
  background-color: var(--lightBgO);
}

.lit-processCard{
  color: var(--darkText) !important;
  border-radius: 30px;
  height: 100%;
}

.lit-featBlogCard img{
  position: inherit;
  width: 100%;
  height: 250px;
}

.lit-featBlogCard{
  border-radius: 10px;
  overflow: hidden;
}

.lit-featBlogCard a{
  color: var(--extraBg);
  text-decoration: none;
}

.lit-gloOff{
  font-weight: 600;
}

.lit-gloOffCard{
  color: var(--lightText) !important;
  border-radius: 10px;
  height: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
}

#lit-offInd{
  background-image: url('../img/litImages/offIndia.jpg');
}

#lit-offCan{
  background-image: url('../img/litImages/offCanada.jpg');
}

.lit-footer-heading
{
  
  font-size: 20px;
  font-weight: 600;
 
  margin-top: 15px !important;

}

.lit-footerback
{
  background: #000000 none no-repeat scroll 0 0;
  color: var(--lightText) !important;
  position: relative;
}

.lit-footerback::before{
  content: '';
  background-image: url('/src/assets/img/litImages/giphy.gif');
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  opacity: 0.1;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.lit-footerback  span{
  display: block;
  font-size: 15px !important;
  padding:6px 0px;
  font-weight: 500;
}

.lit-footerback p
{
  color: var(--lightText);
  font-size: 15px !important;
}

.lit-testItemUpper{
  background-color: rgba(0, 0, 0, 0.329);
  color: var(--lightText);
}

.lit-testItem{
  border-radius: 10px;
  overflow: hidden;
}

.lit-testComment, .lit-testName{
  font-size: 18px;
  line-height: 27px;
  font-weight: 600 !important;
  padding-bottom: 20px;
}

.lit-testItemBottom{
  background-color: rgba(0, 0, 0, 0.329);
  border-top: 2px solid var(--darkBg);
}

.lit-testimonials{
  background-image: url('../img/litImages/testmonialsBG.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.lit-testimonials h4{
  font-size: 30px !important;
  color: var(--lightBgO);
}

.lit-testimonials li.active{
  background-color: var(--darkBg);
}

.lit-textTheme {
  color: var(--lightBgO);
  font-weight: 400;
}

.lit-textThemeAlt {
  color: var(--darkBg) !important;
  font-weight: 400;
}

.lit-testimonials a.left.carousel-control.carousel-control-prev, .lit-testimonials a.right.carousel-control.carousel-control-next{
  background-color: var(--darkBg);
}

.lit-servicesTitle{
  color: var(--darkBg);
  font-weight: 400;
}

.lit-servicesContent{
  color: var(--darkText);
  font-weight: 400 !important;
}

.lit-servicesList{
  font-weight: 400;  
}

.lit-servicesHead{
  font-weight: 400;
}

.lit-Middleline
{
   border-right: 3px solid var(--darkBg);
   height: 500px;
}
/* Header NavBar */

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

	.dropdown-menu li{
		position: relative;
	}
	.dropdown-menu .submenu{ 
		display: none;
		position: absolute;
		left:100%; top:-7px;
	}
	.dropdown-menu .submenu-left{ 
		right:100%; left:auto;
	}

	.dropdown-menu > li:hover{ background-color: #f1f1f1 }
	.dropdown-menu > li:hover > .submenu{
		display: block;
	}

  .Dropdown_menu__1xIbZ
  {
    height: 400px !important;
  }
}	

@media all and (max-width: 992px) {
  .lit-drpdwn{
    color: var(--darkText) !important;
    line-height: 2em;
  }  
}
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {

.dropdown-menu .dropdown-menu{
		margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
}

}	
/* ============ small devices .end// ============ */



.lit-drpdwn
{
  width: auto;
  background-color: transparent;
  color: var(--darkText);
  font-weight: 600;
  border: none;
  padding: 0px 5px;
  cursor: pointer;
}

.lit-drpdwn:focus
{
  border: none;
}

.navbar.navbar-transparent .lit-drpdwn{
  color: var(--lightText);
}

.lit-portfolioItem{
  overflow: hidden;
  border-radius: 10px;
}

.lit-portfolioItem > div{
  width: 100%;
  height: 100%;
  background-color: var(--darkBgO);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.lit-portfolioItem > div > span > a{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--lightText);
  font-size: larger;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}

.lit-portfolioItem > img{
  height: 250px;
  width: 100%;
}

.lit-portfolioItem:hover > img{
  transform: scale(1.5);
  transition: 0.7s;
}

.lit-portfolioItem:hover > div{
  transition: 0.5s;
  opacity: 1;
}

.lit-portfolioBG{
  background-image: url('/src/assets/img/litImages/portfolioBG.jpg');
}

.lit-small{
  /* height: fit-content !important; */
  min-height: 80vh !important;
  margin-top: -50px !important; 
}

.lit-subMenu, .lit-subMenu a{
  font-weight: 400 !important;
}

.lit-aboutBG{
  background-image: url('../img/litImages/aboutBG.jpg');
}

.lit-trainingBG{
  background-image: url('../img/litImages/trainingBG.jpg');
}

.lit-servicesDrpdwn{
  position: fixed;
  left: 0;
  top: 0;
  max-width: 90vw;
  height: 100vh;
  width: 800px;
  background-color: var(--lightText);
  border-right: 4px solid var(--darkBg);
  transition: 0.5s;
  z-index: auto;
}

.lit-menuActive{
  overflow: hidden;
  width: 0px !important;
  border: none !important;  
}

.lit-logo{
  background-image: url('../img/litImages/logo.png');
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;

}

.lit-homeHeader{
  background-image: url('../img/litImages/homeBG.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: purple;
}

.lit-footerback p{
  font-weight: 500 !important;
}

.lit-contactBG{
  background-image: url('../img/litImages/contactBG.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: purple;
  background-position: top;
}

.lit-techInService{
  color: var(--darkBg);
  font-size: 24px;
  font-weight: 500px;
  text-align: center;
}

.lit-careerBG{
  background-image: url('../img/litImages/careerBG.jpg');
}

.lit-keyAchCard{
  height: 100%;
}

.lit-themeBg{
  background-color: var(--lightBgO) !important;
}

.lit-my-auto{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.lit-drpdwn > .nav-link{
  text-transform: capitalize !important;
  font-size: 14px !important;
}

.lit-blogBG{
  background-image: url('/src/assets/img/litImages/blogBG.jpg');
}

.lit-backtotop{
  background-color: var(--darkBg);
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  color: var(--lightText);
  cursor: pointer;  
  padding: 10px;
  border: 2px solid var(--lightText);
  display: none;
}





/* overwrite */

/* home slider  */
.page-header.section-dark {
    z-index: 0;
    margin-top: -21px;
}

div#carousel{
  background-color: transparent !important;
}

#carousel .card{
  box-shadow: none !important;
}

.content-center{
  z-index: 2 !important;
  color: aquamarine;
  width: 95vw !important;
  text-align: center;
}
.page-carousel.card{
  background-color: transparent !important;
  color: var(--lightText);
}

.card-register {
  background-color: var(--darkText) !important;
  border-radius: 15px;

}

/* .page-header .filter::after, */
.section-image:before{
  background-color: transparent !important;
}

@media screen and (max-width: 991px){

  .navbar-collapse ul {
    height: auto !important;
  }
}

a{
  color: inherit !important;
  font-weight: inherit !important;
}

.page-header h1{
  color: var(--lightText);
  font-weight: 600;
}

.lit-404BG{
  background-image: url('../img/litImages/404BG.jpg');
}

.lit-techImg{
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.lit-techImg:hover{
  filter: grayscale(1);
}



/* Menu */

.dropdown-menu {
  padding: 1em 3em 1em 1em;
  background-color: var(--lightBgO) !important;
  width: 600px;
  max-width: 90vw;
  margin-top: 20px !important;
  margin-left: 0px !important;

}
.dropdown-menu a{
  font-size: 12px;
}

@media only screen and (max-width: 720px) {
  /* .eventsNav {
    width: 22em;
    padding-bottom: 1em;
  } */
  a#basic-nav-dropdown{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
@media only screen and (max-width: 600px) {
  .lit-drpdwn > .nav-link{
    color: black !important;
  }
  .dropdown-menu {
    /* margin-left: -50px !important; */
    margin-top: 0px !important;
  }

  

}

